import React, { Component, lazy, Suspense } from 'react';
import Preloader from '../preLoader/preloader'

const LandingPage = lazy(() => import('./LandingPage'))

export default class Home extends Component {

  constructor(props) { //props constructor
    super(props);

    this.state = {
      preLoader: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        preLoader: false
      })
    }, 5000);
  }

  render() {
    return (
      <div>
        <Suspense fallback={<Preloader />}>
          <div className="landingPage-animate-opacity" ><LandingPage /></div>
        </Suspense>
      </div>
    )
  }
}