import React, { Component } from 'react';
import './App.css';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import Home from './components/staticPages/Home'; //AboutUs component

class App extends Component {  
  render() {
    return (
      <Router>
        <Switch> 
          <Route exact path="/" component={Home}/>
        </Switch>
      </Router>
    ); 
  }
}
export default App;
