import React, { Component } from 'react';

import './spinkit.css';

export default class preLoader extends Component {

    render() {
        return (
            <div class="middle">
                <div class="sk-swing">
      <div class="sk-swing-dot"></div>
      <div class="sk-swing-dot"></div>
    </div>
            </div>   
        )
    }
}